<template>
    <div class="authorization-page onboarding">
        <div class="form">
            <router-link @click="reload" :to="{name: 'Home'}" class="logo">
                <img :src="require('@/assets/img/logo.png')" class="img-fluid" alt="EURECBROKER">
            </router-link>
            <div class="onboarding-step step-wide">
                <h1>{{ $t("Who you are?") }}</h1>
                <p class="step-intro">{{ $t('Select preferred account type below') }}</p>
                <form>
                    <div class="step-actions d-flex justify-content-center">
                        <router-link @click="$store.getters.user.status='onboarding_candidate'" :to="{name: 'Onboarding'}" class="btn btn-secondary">
                            {{ $t('I want to work') }}
                        </router-link>

                        <router-link @click="$store.getters.user.status='onboarding_client'" :to="{name: 'Onboarding'}" class="btn btn-secondary">
                            {{ $t('I want to hire') }}
                        </router-link>
                    </div>
                </form>
            </div>
        </div>
        <div class="side">
            <img :src="require('@/assets/img/background-authorization-page.png')" :alt="$t('RecBroker authorization image')" />
            <div class="circle black"></div>
            <div class="circle blue"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PickOnboarding",
    }
</script>